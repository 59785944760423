<template>
    <div class="center-horizontally">
        <v-card-action><v-icon color="white" size="large" @click="redirect('profilePage')"
                class="mt-4">mdi-chevron-left</v-icon>
        </v-card-action>
        <v-card-text class="signInTitle mt-2">Need Help ?</v-card-text>

        <v-card-text class="sub-label mt-n6">
            <div class="sub-label">
                <div class="live-Sport ml-n1 mb-2 ">We will respond to all of your Questions in any of platforms below.
                </div>


            </div>
            <div class="sub-label">
                <v-row align="center" no-gutters>
                    <v-col class="d-flex" cols="auto">
                        <v-img :src="require('@/assets/whatsapp.png')" class="contact-img"></v-img>
                    </v-col>
                    <v-col class="d-flex ml-3" cols="auto">
                        <span>+254714451463</span>
                    </v-col>
                </v-row>
            </div>

            <div class="sub-label">
                <v-row align="center" no-gutters>
                    <v-col class="d-flex" cols="auto">
                        <v-img :src="require('@/assets/gmail.png')" class="contact-img"></v-img>
                    </v-col>
                    <v-col class="d-flex ml-3" cols="auto">
                        <span>mailto:support@sakata.co.ke</span>
                    </v-col>
                </v-row>
            </div>
            <div class="sub-label">
                <v-row align="center" no-gutters>
                    <v-col class="d-flex" cols="auto">
                        <v-img :src="require('@/assets/instagram.png')" class="contact-img"></v-img>
                    </v-col>
                    <v-col class="d-flex ml-3" cols="auto">
                        <span>@Sakata</span>
                    </v-col>
                </v-row>
            </div>
            <div class="sub-label">
                <v-row align="center" no-gutters>
                    <v-col class="d-flex" cols="auto">
                        <v-img :src="require('@/assets/facebook.png')" class="contact-img"></v-img>
                    </v-col>
                    <v-col class="d-flex ml-3" cols="auto">
                        <span>@Sakata</span>
                    </v-col>
                </v-row>
            </div>
            <div class="sub-label">
                <v-card-text class="signInTitle mt-2"> We Value your Feedback.</v-card-text>
                <v-row align="center" no-gutters>
                    <v-textarea label="Give us your opinion" row-height="25" rows="3" variant="outlined" auto-grow
                        shaped></v-textarea>

                </v-row>
            </div>

            <v-card-actions class="mt-n4">
                <v-spacer />
                <v-btn size="large" class="test-login-btn"><span class="login--text">Send</span>

                </v-btn>
                <v-spacer />
            </v-card-actions>

        </v-card-text>
    </div>
</template>

<script>
export default {
    name: "testPage",

    data() {
        return {
            isValid: false,

            screenSize: "",
        };
    },

    computed: {},

    mounted() {
        this.checkScreenSize(); // Check screen size initially
        window.addEventListener("resize", this.checkScreenSize);
    },
    methods: {
        checkScreenSize() {
            const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
            this.screenSize = isSmallScreen ? "Small" : "Large";
        },

        redirect(val) {
            this.$router.push({ name: val });
        },
    },

    watch: {},
};
</script>

<style>
@import url("../style.css");
</style>
