<template>
    <div class="center-horizontally">
  <!-- <div class="mt-3"> -->
  <v-row class="mt-n1 mx-1">
    <v-col cols="12" sm="12" md="12" lg="12" >
      <v-img :src="require('@/assets/bet.jpeg')" class="main-img" max-width="540" max-height="250" />
    </v-col>
   
  </v-row>
  <!-- </div> -->

  <!-- <div class="image-row"> -->
  <v-row no-gutters align="center" class="mt-4 mx-3">
    <v-col cols="4" @click="redirectToFair()">
      <v-img :src="require('@/assets/ball.png')" class="small-img mr-2 ml-1" />
    </v-col>

    <v-col cols="4" @click="redirectToLive()">
      <v-img :src="require('@/assets/av.png')" class="small-img mr-2" />
    </v-col>
    <v-col cols="4" @click="redirectToCoin()">
      <v-img :src="require('@/assets/jetx.png')" class="small-img  mr-1"  />
    </v-col>
    <v-col cols="4" @click="redirectToRock()" class="mt-2">
      <v-img :src="require('@/assets/rock.jpg')" class="small-img mr-2 ml-1" />
    </v-col>
    <v-col cols="4" class="mt-2">
      <v-img :src="require('@/assets/cas.png')" class="small-img mr-2" />
    </v-col>
    <v-col cols="4" class="mt-2">
      <v-img :src="require('@/assets/cas.png')" class="small-img mr-1 " />
    </v-col>
  </v-row>

  <v-row class="mt-4 mx-1">
    <v-col cols="12" sm="12" md="12" lg="12" >
      <v-img :src="require('@/assets/welcome.jpeg')" class="main-img" max-width="540" max-height="250" />
    </v-col>
   
  </v-row>
  <!-- </div> -->
  <router-view></router-view>
</div>
</template>

<script>
export default {
  name: "LandingPage",

  beforeRouteEnter(to, from, next) {
    next(() => {});
  },

  data() {
    return {
      screenSize: "",
      showIframe: true,
      open: ["Admin"],
      buttons: [
        {
          label: "Games",
          icon: "mdi-puzzle",
          iconClass: "games-text",
          color: "#fdf4ff",
        },
        {
          label: "Free Bets",
          icon: "mdi-ticket-confirmation",
          iconClass: "bets-text",
          color: "#eff6ff",
        },
        {
          label: "Boosted Odds",
          icon: "mdi-ticket-confirmation",
          iconClass: "boosted-text",
          color: "#f7fee7",
        },
        {
          label: "Player to Score",
          icon: "mdi-tshirt-crew",
          iconClass: "player-text",
          color: "#f5f3ff",
        },
        {
          label: "Vuna Affiliate",
          icon: "mdi-ticket-confirmation",
          iconClass: "vuna-text",
          color: "#fdf4ff",
        },
      ],
    };
  },
  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
  },

  methods: {
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },

    redirectToLive() {
      this.$router.push({ name: "leftSide" });
    },
    redirectToFair() {
      this.$router.push({ name: "fairCrash" });
    },
    redirectToCoin() {
      this.$router.push({ name: "coinFlip" });
    },
    redirectToRock() {
      this.$router.push({ name: "rockPaper" });
    },
  },
};
</script>

<style>
@import url("../style2.css");
</style>
