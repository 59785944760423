<template>
  <!-- <v-container fluid class="login-container justify-center align-center"> -->
    <div class="center-horizontally">
      <!-- <v-card variant="flat" class="Login-Card"> -->

        <!-- <v-card-text class="signInTitle">Hello, Welcome to LimboBust</v-card-text> -->
        <v-card-text class="signInTitle mt-6">Enter Phone Number Below</v-card-text>
      

        <v-alert
          dense
          v-if="alertMessage.status !== ''"
          :type="alertMessage.status === 'success' ? 'success' : 'error'"
          @click="dismissAlert"
        >
          <span class="pass-text">{{ alertMessage.message }}</span>
        </v-alert>

        <v-card-text class="login-form">
          <v-form ref="loginForm" v-model="isValid">
          

            <v-card-text class="sub-label mt-n6"><span class="asterisk">*</span>Phone Number</v-card-text>
            <input
              ref="phone"
              density="compact"
              :rules="rules.required"
              v-model="formData.phone"
              placeholder="Enter phone"
              class="log-field"
              type="number"
              variant="outlined"
            />
          </v-form>
        </v-card-text>

        <v-card-actions class="mt-0">
          <v-spacer />
          <v-btn size="large" class="test-login-btn" @click="Forgot()"
            ><span class="login--text" v-if="!loginState">Forgot</span>
            <v-progress-circular
              v-if="loginState"
              indeterminate
              color="purple"
            ></v-progress-circular>
          </v-btn>
          <v-spacer />
        </v-card-actions>

        <v-card-actions class="mt-4">
          <v-spacer />
          <v-btn
            :to="{ name: 'signUp' }"
            class="capitalize-first-letter text-decoration-underline forgot-text mt-n5"
          >
            Create New Account</v-btn
          >
          <v-spacer />
        </v-card-actions>

    </div>
 
</template>

<script>
export default {
  name: "forgotPassword",

  data() {
    return {
      isValid: false,
      loginState: false,
      screenSize: "",

      isOtpValid: false,
      formData: {
        phone: "",
        password: "",
      },
      otpformData: {
        otp: "",
      },
      showPassword: false,
      rules: {
        required: [
          (value) => !!value || "Required.",
          (value) => /^[0-9]*$/.test(value) || "Only numbers are allowed.",
        ],
      },
    };
  },

  computed: {
    alertMessage() {
      return this.$store.getters["auth/authGetters"]("forgotAlert");
    },
    otpMessage() {
      return this.$store.getters["auth/authGetters"]("otpAlert");
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
    this.alertMessage.status = "";
    this.alertMessage.message = "";
  },
  methods: {
    Forgot() {
      if (this.formData.phone === "") {
        this.alertMessage.status = "error";
        this.alertMessage.message = "all fields required";
      } else {
        this.formData.phone = String(this.formData.phone)
        this.loginState = true;
        this.$store.dispatch("auth/forgot", this.formData);
      }
    },
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
   
    verify: function () {
      if (!this.isOtpValid) {
        this.$refs.otpForm.validate();
      } else {
        if (this.otpformData.otp.length === 4) {
          this.$store.dispatch("Auth/verifyOtp", this.otpformData);
        }
      }
    },
    Resend: function () {
      if (!this.isOtpValid) {
        this.$refs.otpForm.validate();
      } else {
        if (this.otpformData.otp.length === 4) {
          this.$store.dispatch("Auth/verifyOtp", this.otpformData);
        }
      }
    },
    dismissAlert() {
      // Clear the alert
      this.alertMessage.status = "";
      this.alertMessage.message = "";
    },
  },

  watch: {
    alertMessage: {
      handler: function () {
        if (this.alertMessage.code !== 1000) {
          this.loginState = false;
        } else {
          this.$router.push({
            name: "otpPage",
            params: {
              code: btoa(this.formData.phone),
            },
          });
        }
      },
    },
  },
};
</script>

<style>
@import url("../style.css");
</style>
