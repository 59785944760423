<template>
  <div>
 
      <!-- <v-card variant="flat" class="terms-card"> -->
      <v-card-actions class="mt-n4"
        ><v-icon
          color="white"
          size="large"
          @click="redirect('profilePage')"
          class="mt-4"
          >mdi-chevron-left</v-icon
        >
        <v-spacer/>
        <v-card-text class="transTitle">BET HISTORY</v-card-text>
        <v-spacer/>
      </v-card-actions>
     
            <v-list class="history-column sub-label mt-n2" v-if="betHistory.length > 0">
              <v-row class="ml-0">
                <v-col cols="4">
                  <span class="first-col-text">Amount</span>
                </v-col>
                <v-col cols="4">
                  <span class="first-col-text">Game</span>
                </v-col>
                <!-- <v-col cols="4">
                  <span class="first-col-text">Date</span></v-col
                > -->
              </v-row>

              <v-list-item
                v-for="(item, i) in betHistory ? betHistory : []"
                :key="i"
              >
                <v-row>
                  <v-col>{{ item.amount }}</v-col>
                  <v-col>{{ item.game }}</v-col>
                  <v-col>{{ item.created_on }}</v-col>
                </v-row>
              </v-list-item></v-list
            >

            <v-card-text class="transTitle ml-5" v-if="betHistory.length === 0">No data Available</v-card-text>
      
      </div>
   
  <!-- </div> -->
</template>

<script>
import { AuthService } from "@/modules/auth";
export default {
  name: "testPage",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (AuthService.check()) {
        v.$store.dispatch("graph/getBetHistory", {
          user_id: JSON.parse(AuthService.user).user_id,
        });
      }
    });
  },

  data() {
    return {
      dialog: true,
      isValid: false,
      screenSize: "",
      headers: [
        { title: 'amount', key: 'amount', align: 'end' },
        { title: 'game', key: 'game', align: 'end' }
      ],
    };
  },

  computed: {
    betHistory() {
      return this.$store.getters["graph/graphGetters"]("betHistory");
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },

    redirect(val) {
      this.$router.push({ name: val });
    },
  },

  watch: {},
};
</script>

<style>
@import url("../style.css");
</style>
