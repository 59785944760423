<template>

    <!-- <div class="content-wrapper"> -->
        <iframe :src="iframeSrc" :style="iframeStyle" class="iframe-responsive" frameborder="0" allowfullscreen>
        </iframe>
    <!-- </div> -->

</template>

<script>
import { AuthService } from "@/modules/auth";
export default {
    name: 'IframeComponent',

    data() {
        return {
            screenSize: "",
            width: this.screenSize !== 'Small' ? '600px' : '110%',
            iframeSrc: `https://lipa.eaglerift.org?token=${AuthService.token}`, // Replace with the URL you want to load
        };
    },

    mounted() {
        this.checkScreenSize(); // Check screen size initially
        window.addEventListener("resize", this.checkScreenSize);
    },
    methods: {
        checkScreenSize() {
            const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
            this.screenSize = isSmallScreen ? "Small" : "Large";
        },
    }
};
</script>

<style scoped>
/* You can add styles for your iframe here */
.iframe-responsive {
    max-width: 100%;
    width: 100%;
    height: 80vh;
    border: none;
    overflow: scroll;
}

.iframe-responsive::-webkit-scrollbar {
    width: 6px;
}

.iframe-responsive::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.iframe-responsive::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
}

.iframe-responsive::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}
</style>