<template>
  <div class="center-horizontally">
    <!-- <v-card-text class="signInTitle mt-4">Promotion</v-card-text> -->
 
    <v-row class="mt-6 mx-1">
  
      <v-img :src="require('@/assets/welcome.jpeg')" class="img" />
    </v-row>
    <v-row class="mt-6 mx-1">
   
      <v-img :src="require('@/assets/affiliate.png')" class="img" />
    </v-row>
    <v-row class="mt-6 mx-1">
   
   <v-img :src="require('@/assets/level.png')" class="img" />
 </v-row>
  </div>
</template>

<script>
// import { AuthService } from "@/modules/auth";
export default {
  name: "promotionPage",

  data() {
    return {};
  },

  computed: {},

  mounted() {
    // this.checkScreenSize(); // Check screen size initially
    // window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    // checkScreenSize() {
    //   const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
    //   this.screenSize = isSmallScreen ? "Small" : "Large";
    // },
   
  },

  watch: {},
};
</script>

<style>
@import url("../style.css");
</style>
