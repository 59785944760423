<template>
  <v-overlay v-model="overlay" class="align-center justify-center" opacity="10">
    <v-dialog v-model="dialog" persistent class="dep-dialog" transition="dialog-bottom-transition">
      <v-card class="deposit-card">
        <v-card-text class="signInX">
          <v-card-actions>
            <div class="signInLeft">
              <!-- <v-icon>mdi-chevron-left</v-icon> -->
            </div>
            <v-spacer />
            <div class="signInLeft font-weight-bold">
              Wallet {{ User ? User.phone : "" }}
            </div>
            <v-spacer />
            <v-icon @click="redirectToLanding">mdi-close-circle</v-icon>
          </v-card-actions>
        </v-card-text>
        <v-alert dense v-if="alertMessage.status !== ''" :type="alertMessage.status === 'success' ? 'success' : 'error'"
          @click="dismissAlert">
          <span class="pass-text">{{ alertMessage.message }}</span>
        </v-alert>

        <v-card-text class="mt-n3">
          <v-card-actions v-if="screenSize === 'Large'">
        
            <v-spacer />
            <v-sheet variant="text" flat class="bep-reg-btn text-none mx-3">
              <span class="mt-3 label-text">Balance</span> <br />
              <span class="mt-3 Aep-reg-btn">{{
                User.balance ? formatNumberWithCommas(User.balance) : "N/A"
              }}</span>
            </v-sheet>

            <v-spacer /></v-card-actions>
        </v-card-text>
        <v-card-text class="mt-n3">
          <v-card-actions>
            <v-spacer />
            <v-btn-toggle class="toggle-btn" v-model="toggle" color="" variant="outlined" divided>
              <v-btn size="small" @click="toggle = 'Balance'" flat :class="toggle === 'Balance'
                ? ' active-withdraw-btn'
                : ' non-active-withdraw-btn'
                ">Deposit</v-btn>

              <v-btn size="small" flat :class="toggle === 'Withdrawable'
                ? ' active-withdraw-btn'
                : ' non-active-withdraw-btn'
                " @click="toggle = 'Withdrawable'">Withdraw</v-btn>

            </v-btn-toggle>

            <v-spacer />
          </v-card-actions>
        </v-card-text>

        <div v-if="tab === 0">
          <v-card-text class="mt-n3 main-text" v-if="toggle === 'Balance'">
            Enter amount, click [Top Up Now] button and input Mpesa PIN to
            authorize transaction or deposit via Paybill: 566518</v-card-text>

          <v-card-text class="login-form">
            <v-form ref="loginForm" v-model="isValid">
              <div class="signInLeft mt-n6" v-if="toggle === 'Balance' || toggle === 'Withdrawable'">
                Amount in Kshs
              </div>

              <div class="signInLeft mt-n6" v-else>Code</div>
              <!--Amount-->
              <v-text-field ref="" label="" :rules="rules.required" v-model="formData.amount" placeholder=""
                style="color: #fff" type="number" variant="outlined" />
            </v-form>
          </v-card-text>

          <v-card-actions class="mt-0">
            <v-spacer />
            <v-btn @click="performAction(toggle)" block size="x-large" :disabled="!isValid" class="withdraw-btn">
              <span class="login--text" v-if="!spinner">{{ buttonText }}</span>
              <v-progress-circular v-if="spinner" indeterminate color="purple"></v-progress-circular>
            </v-btn>

            <v-spacer />
          </v-card-actions>

          <!-- <v-card-text class="mt-n3 main-text" v-if="toggle === 'Balance'">
            <p>Instructions:</p>
            <p>Minimum Withdrawal amount: 100/-</p>
            <p>Maximum Withdrawal amount: 150,000/-</p>
            <p>Daily Transaction Limit: 300,000/-</p>
          </v-card-text> -->

          <v-card-text class="mt-n3 main-text" v-if="toggle === 'Withdrawable'">
            <p>Instructions:</p>
            <p>Minimum Withdrawal amount: 100/-</p>
            <p>Maximum Withdrawal amount: 150,000/-</p>
            <p>Daily Transaction Limit: 300,000/-</p>

            <p class="mt-2">
              Withholding Tax (WHT): As provided for by the Income Tax Act, Cap
              472, all gaming companies are required to withhold winnings at a
              rate of 20%. This is the Withholding Tax. In compliance with the
              law, we will deduct and remit to KRA 20% of all winnings.
            </p>
          </v-card-text>

        </div>
        <div v-if="tab === 1">
          <v-card-actions>
            <v-spacer />
            <v-avatar color="white" size="86">
              <v-img alt="Avatar" src="./logo.png"></v-img></v-avatar>
            <v-spacer />
          </v-card-actions>
          <v-card-actions>
            <v-spacer />
            <div class="payBill">566518</div>
            <v-spacer />
          </v-card-actions>
          <v-card-text>
            <v-row><v-col cols></v-col><v-col></v-col></v-row>
            <v-row>
              <v-col cols="2">
                <v-avatar color="#e505ff" :size="screenSize !== 'Large' ? '26' : '46'">
                  <div :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'
                    ">
                    1
                  </div>
                </v-avatar></v-col>
              <v-col cols="10">
                <div :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'
                  ">
                  Go to M-PESA Menu on your Mobile Phone
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                <v-avatar color="#e505ff" :size="screenSize !== 'Large' ? '26' : '46'">
                  <div :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'
                    ">
                    2
                  </div>
                </v-avatar></v-col>
              <v-col cols="10">
                <div :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'
                  ">
                  Select Lipa M-PESA
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2">
                <v-avatar color="#e505ff" :size="screenSize !== 'Large' ? '26' : '46'">
                  <div :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'
                    ">
                    3
                  </div>
                </v-avatar></v-col>
              <v-col cols="10">
                <div :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'
                  ">
                  Select PayBill
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2">
                <v-avatar color="#e505ff" :size="screenSize !== 'Large' ? '26' : '46'">
                  <div :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'
                    ">
                    4
                  </div>
                </v-avatar></v-col>
              <v-col cols="10">
                <div :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'
                  ">
                  Enter XXXXXX as the Business Number
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
  </v-overlay>
  <v-snackbar :color="status_code === 1000 ? 'green' : 'red'" v-model="visible"
    :timeout="timeout" class="mt-n12">
    {{ message }}

  </v-snackbar>
</template>

<script>
import { AuthService } from "@/modules/auth";
import eventBus from "@/utils/eventBus";

export default {
  name: "DepositPage",

  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (AuthService.check()) {
        v.$store.dispatch("auth/getUser", {
          phone: JSON.parse(AuthService.user).phone,
        });
      }
    });
  },

  data() {
    return {
      overlay: true,
      visible: false,
      message: null,
      dialog: true,
      isValid: false,
      loginState: false,
      screenSize: "",
      toggle: "Balance",
      tab: 0,
      timeout: 2000,

      BalanceTest: [
        { variant: "outlined", amount: 48, text: "Get 50", active: false },
        { variant: "outlined", amount: 95, text: "Get 100", active: false },
        { variant: "outlined", amount: 477, text: "Get 500", active: false },
        { variant: "outlined", amount: 977, text: "Get 1000", active: false },
      ],
      variants: [
        { variant: "outlined", amount: 48, text: "Get 50", active: false },
        { variant: "outlined", amount: 95, text: "Get 100", active: false },
        { variant: "outlined", amount: 477, text: "Get 500", active: false },
        { variant: "outlined", amount: 977, text: "Get 1000", active: false },
      ],
      formData: {
        amount: "",
      },
      showPassword: false,
      status_code:0,
      buttonTexts: {
        Balance: "Top Up Now",
        Withdrawable: "WithDraw" // Change this as per your requirement
      },
      rules: {
        required: [
          (value) => !!value || "Required.",
          (value) => /^[0-9]*$/.test(value) || "Only numbers are allowed.",
        ],
      },
      intervalId: null,
    };
  },

  computed: {
    alertMessage() {
      return this.$store.getters["auth/authGetters"]("alert");
    },
    buttonText() {
      return this.buttonTexts[this.toggle] || "Top Up Now"; // Default to 'Top Up Now' if toggle value is not found in the buttonTexts object
    },
    User() {
      return this.$store.getters["auth/authGetters"]("userD");
    },
    spinner() {
      return this.$store.getters["graph/graphGetters"]("spinner");
    },
    betText() {
      return this.$store.getters["graph/graphGetters"]("betText");
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
    this.alertMessage.status = "";
    this.alertMessage.message = "";

    eventBus.on("showToast", (val) => {
      console.log(val)
      this.status_code = val.code
      this.visible = true;
      this.message = val.message;
      this.formData.amount = null
    });
    this.startPolling();
  },
  methods: {
    stopPolling() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
    formatNumberWithCommas(number) {
      const val = Number(number);
      return val.toLocaleString();
    },
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },

    redirectToLanding() {
      this.$router.push({ name: "LandingPage" });
    },
    dismissAlert() {
      // Clear the alert
      this.alertMessage.status = "";
      this.alertMessage.message = "";
    },
    clearDeposit() {
      this.$refs.loginForm.reset();
      for (let i = 0; i < this.variants.length; i++) {
        this.variants[i].active = false;
      }
    },

    activateButton(val) {
      for (let i = 0; i < this.variants.length; i++) {
        if (this.variants[i] !== val) {
          this.variants[i].active = false;
        }
      }

      if (val.active) {
        val.active = false;
        this.$refs.loginForm.reset();
      } else {
        val.active = true;
        this.formData.amount = val.amount;
      }
    },
    performAction(val) {
      const ID = JSON.parse(AuthService.user).user_id;
      if (val === "Withdrawable") {
        this.$store.dispatch("graph/withdraw", {
          user_id: ID,
          amount: this.formData.amount,
        });
      } else{
        this.$store.dispatch("graph/deposit", {
          user_id: ID,
          amount: this.formData.amount,
          phone: JSON.parse(AuthService.user).phone
        });
      }
    
    },
    startPolling() {
      this.intervalId = setInterval(() => {
        this.$store.dispatch("auth/getUser", {
          phone: JSON.parse(AuthService.user).phone,
        });
      }, 5000); // 5000 milliseconds = 5 seconds
    },
  },
  beforeUnmount() {
    this.stopPolling();
  },

  watch: {
    alertMessage: {
      handler: function () {
        if (this.alertMessage.status !== 1000) {
          this.loginState = false;
        }
      },
    },
  },
};
</script>

<style>
@import url("../style.css");

.v-overlay.v-overlay--active {
  opacity: 120;
}
</style>
