import { createApp } from "vue";
import App from "./App.vue";
import vuetifyApp from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import graphModule from "./modules/graph/graphStore";
import authModule from "./modules/auth/authStore";
import eventBus from "@/utils/eventBus";

const app = createApp(App);

app.use(router);
app.use(store);
app.use(graphModule);
app.use(authModule);
app.use(eventBus);

app.use(vuetifyApp);

app.mount("#app");
