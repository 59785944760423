// import call from "@/service/http";
// import constants from "./mainConstants";

const graphModule = {
  namespaced: true,
  state: {

  },
  mutations: {
  

    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    graphGetters: (state) => (val) => state[val],
  },
  actions: {
   
  },
};

export default graphModule;
