<template>

    <!-- <div class="content-wrapper"> -->
        <iframe :src="iframeSrc" scrolling="no" class="iframe-responsive" frameborder="0" allowfullscreen>
        </iframe>
    <!-- </div> -->

</template>

<script>
// import { AuthService } from "@/modules/auth";
export default {
    name: 'IframeComponent',

    data() {
        return {
            screenSize: "",
            width: this.screenSize !== 'Small' ? '600px' : '110%',
            iframeSrc: 'https://faircrash.pages.dev/?user=4675264313&token=7vl9tf3kgpm180di9ic13yaek', // Replace with the URL you want to load
        };
    },

    mounted() {
        this.checkScreenSize(); // Check screen size initially
        window.addEventListener("resize", this.checkScreenSize);
    },
    methods: {
        checkScreenSize() {
            const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
            this.screenSize = isSmallScreen ? "Small" : "Large";
        },
    }
};
</script>

<style scoped>
/* You can add styles for your iframe here */
.iframe-responsive {
    height: 100vh;
    /* border: 2px red solid; */
    overflow: scroll;
    width: 100%;
    /* overflow-y: hidden; */
}

.iframe-responsive::-webkit-scrollbar {
    width: 6px;
}

.iframe-responsive::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.iframe-responsive::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
}

.iframe-responsive::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}


.content-wrapper {
    width: 100%;
    height: 100vh;
    overflow: auto;
}



.content-wrapper::-webkit-scrollbar {
    width: 6px;
}

.content-wrapper::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.content-wrapper::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
}

.content-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}
</style>