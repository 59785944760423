<template>

  
    <v-card variant="outlined" class="mx-auto profile-card mt-3">
      <v-card-actions class="mt-n3 main-text">
        <v-spacer /> Main Wallet (KSH) <v-spacer />
      </v-card-actions>
      <v-card-actions class="mt-n3 cash-bal">
        <v-spacer /> {{
                User.balance ? formatNumberWithCommas(User.balance) : "N/A"
              }} <v-spacer />
      </v-card-actions>
   
    </v-card>

    <v-row class="mt-3">
      <v-col cols="6"
        ><v-card color="blue" variant="outlined" class="mx-auto">
          <v-card-actions>
            <div class="small-signInLeft ml-4">Coins</div>
            <v-spacer />
            <v-btn v-if="screenSize === 'Large'" flat class="small-prof-btn text-none mx-3" min-width="82">
              Win Cash</v-btn
            >
          </v-card-actions>
          <div class="cash-text ml-6">0</div>
        </v-card></v-col
      >
      <v-col cols="6"
        ><v-card
          color="blue"
          variant="outlined"
          class="mx-auto"
          max-width="1600"
        >
          <v-card-actions>
            <div class="small-signInLeft ml-4">Gifts (Ksh)</div>
            <v-spacer />
          </v-card-actions>
          <div class="cash-text ml-6">0</div>
        </v-card></v-col
      >
    </v-row>
    <v-row class="mb-5">
      <v-img src="./banner.png" />
    </v-row>

    <v-card-text class="mt-n8">
      <v-row>
        <v-col cols="2">
          <v-avatar
            color="#e505ff"
            class="mt-n2"
            :size="screenSize !== 'Large' ? '26' : '46'"
          >
            <div
              :class="
                screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'
              "
            >
              <v-icon size="small">mdi-file-document</v-icon>
            </div>
          </v-avatar></v-col
        >
        <v-col cols="9" @click="redirect('TRANSHISTORY')">
          <div
            :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
          >
            Transaction History
          </div></v-col
        >
        <v-col cols="1" @click="redirect('TRANSHISTORY')">
          <div
            :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
          >
            <v-icon size="small" class="ml-n3">mdi-chevron-right</v-icon>
          </div></v-col
        >
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-avatar
            class="mt-n2"
            color="blue"
            :size="screenSize !== 'Large' ? '26' : '46'"
          >
            <div
              :class="
                screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'
              "
            >
              <v-icon size="small">mdi-clock</v-icon>
            </div>
          </v-avatar></v-col
        >
        <v-col cols="9" @click="redirect('BETHISTORY')">
          <div
            :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
          >
            Bet History
          </div></v-col
        >
        <v-col cols="1" @click="redirect('BETHISTORY')">
          <div
            :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
          >
            <v-icon size="small" class="ml-n3">mdi-chevron-right</v-icon>
          </div></v-col
        >
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-avatar
            class="mt-n2"
            color="#ef7e57"
            :size="screenSize !== 'Large' ? '26' : '46'"
          >
            <div
              :class="
                screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'
              "
            >
              <v-icon size="small">mdi-cash-multiple</v-icon>
            </div>
          </v-avatar></v-col
        >
        <v-col cols="6" @click="redirect('Referral')">
          <div
            :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
          >
            Invite Friends
          </div></v-col
        >
        <v-col cols="4" @click="redirect('Referral')">
          <div
            :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
          >
            <span
              :class="
                screenSize === 'Large' ? 'get-cash-text ml-12' : 'small-get-cash-text'
              "
              >Get Cash</span
            >
            <v-icon size="small" :class="screenSize !== 'Large' ? 'ml-4' : ''"
              >mdi-chevron-right</v-icon
            >
          </div></v-col
        >
      </v-row>

      <v-row>
        <v-col cols="2">
          <v-avatar
            color="blue"
            class="mt-n2"
            :size="screenSize !== 'Large' ? '26' : '46'"
          >
            <div
              :class="
                screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'
              "
            >
              <v-icon size="small">mdi-lightbulb</v-icon>
            </div>
          </v-avatar></v-col
        >
        <v-col cols="9" @click="redirect('FAQ')">
          <div
            :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
          >
            FAQs
          </div></v-col
        >
        <v-col cols="1" @click="redirect('FAQ')">
          <div
            :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
          >
            <v-icon size="small" class="ml-n3">mdi-chevron-right</v-icon>
          </div></v-col
        >
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-avatar
            color="#557fde"
            class="mt-n2"
            :size="screenSize !== 'Large' ? '26' : '46'"
          >
            <div
              :class="
                screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'
              "
            >
              <v-icon size="small">mdi-emoticon-happy</v-icon>
            </div>
          </v-avatar></v-col
        >
        <v-col cols="9" @click="redirect('CONTACTS')">
          <div
            :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
          >
            Contact Us
          </div></v-col
        >
        <v-col cols="1" @click="redirect('CONTACTS')">
          <div
            :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
          >
            <v-icon size="small" class="ml-n3">mdi-chevron-right</v-icon>
          </div></v-col
        >
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-avatar
            class="mt-n2"
            color="#e505ff"
            :size="screenSize !== 'Large' ? '26' : '46'"
          >
            <div
              :class="
                screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'
              "
            >
              <v-icon size="small">mdi-book-open-variant</v-icon>
            </div>
          </v-avatar></v-col
        >
        <v-col cols="9" @click="redirect('TERMS')">
          <div
            :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
          >
            Terms and Conditions
          </div></v-col
        >
        <v-col cols="1"  @click="redirect('TERMS')">
          <div
            :class="screenSize === 'Large' ? 'signInLeft' : 'small-signInLeft'"
          >
            <v-icon size="small" class="ml-n3">mdi-chevron-right</v-icon>
          </div></v-col
        >
      </v-row>
    </v-card-text>

    <div class="mb-8 mt-6">
      <v-card-actions class="mt-n3">
        <v-spacer />
        <v-btn block size="x-large" class="wd-login-btn" @click="logOut()"
          ><span class="login--text">Logout</span>
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </div>

</template>

<script>
import { AuthService } from "@/modules/auth";
// import footerPage from "./footer.vue";
export default {
  name: "profilePage",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (AuthService.check()) {
        v.$store.dispatch("auth/getUser", {
          phone: JSON.parse(AuthService.user).phone,
        });
      }
    });
  },


  data() {
    return {
      isValid: false,
      loginState: false,
      screenSize: "",
      visible: false,
      tab: 0,
      variants: [
        { variant: "outlined", amount: 48, text: "Get 50", active: false },
        { variant: "outlined", amount: 95, text: "Get 100", active: false },
        { variant: "outlined", amount: 477, text: "Get 500", active: false },
        { variant: "outlined", amount: 977, text: "Get 1000", active: false },
      ],
      formData: {
        amount: "",
      },
      showPassword: false,
      rules: {
        required: [
          (value) => !!value || "Required.",
          (value) => /^[0-9]*$/.test(value) || "Only numbers are allowed.",
        ],
      },
    };
  },

  computed: {
    alertMessage() {
      return this.$store.getters["auth/authGetters"]("alert");
    },
    isLoggedIn() {
      return AuthService.check();
    },
    userDetails() {
      return JSON.parse(AuthService.user);
    },

    User() {
      return this.$store.getters["auth/authGetters"]("userD");
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
    this.alertMessage.status = "";
    this.alertMessage.message = "";
  },
  methods: {
    Login() {
      if (!this.isValid) {
        this.$refs.loginForm.validate();
      } else {
        this.loginState = true;
        this.$store.dispatch("auth/login", this.formData);
      }
    },
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
  
    dismissAlert() {
      // Clear the alert
      this.alertMessage.status = "";
      this.alertMessage.message = "";
    },
    clearDeposit() {
      this.$refs.loginForm.reset();
      for (let i = 0; i < this.variants.length; i++) {
        this.variants[i].active = false;
      }
    },

    activateButton(val) {
      for (let i = 0; i < this.variants.length; i++) {
        if (this.variants[i] !== val) {
          this.variants[i].active = false;
        }
      }
      val.active = !val.active;
      this.formData.amount = val.amount;
    },
    logOut() {
      AuthService.logout();
    },
    redirect(val) {
      this.$router.push({ name: val });
    },
    formatNumberWithCommas(number) {
      const val = Number(number);
      return val.toLocaleString();
    },
  },

  watch: {
    alertMessage: {
      handler: function () {
        if (this.alertMessage.status !== 1000) {
          this.loginState = false;
        }
      },
    },
  },
};
</script>

<style>
@import url("../style.css");
</style>
