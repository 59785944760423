export default {
    SEND_BET : '/limbo_bet',
    AUTO_CASH: '/auto_cashout_early',
    SEND_MESSAGE:'/plane_chat',
    GET_CHATS:'/plane_chat_all',
    GET_ALL_BETS:'/limbo_bet_all',
    TOP_BETS:'/limbo_top_bets',
    MY_BETS:'limbo_my_bets',
    WITHDRAW:'/mpesa_withdraw',
    DEPOSIT:'/mpesa_deposits',
    GET_BETHISTORY:'/bet_history',
    TRANSHISTORY:'transaction_history',
    Referral:'/referral_link'
}