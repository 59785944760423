<template>
  <div class="center-horizontally">
    <v-row class="mx-2 mt-6">
      <v-img :src="require('@/assets/sakata.png')" class="img" />
    </v-row>

    <v-row class="signInTitle mx-2 mt-6"> Sign Up </v-row>

    <v-alert
   class="mt-4"
      dense
      v-if="alertMessage.status !== ''"
      :type="alertMessage.status === 'success' ? 'success' : 'error'"
      @click="dismissAlert"
    >
      <span class="pass-text">{{ alertMessage.message }}</span>
    </v-alert>
    <v-card-text class="login-form">
      <v-form ref="loginForm" v-model="isValid">
        <!--User name-->

        <v-card-text class="sub-label mt-n3 ml-n2">Name</v-card-text>

        <v-text-field
          ref="username"
          density="compact"
          :rules="rules.required"
          placeholder="Enter username"
          class="mx-2 mt-n2 custom-placeholder no-underline"
          v-model="formData.username"
          solo
        ></v-text-field>

        <v-card-text class="sub-label mt-n4 ml-n2">Phone Number</v-card-text>

        <v-text-field
          ref="phone"
          density="compact"
          v-model="formData.phone"
          class="mx-2 mt-n2 custom-placeholder no-underline"
          placeholder="+254 | 7123456789"
          solo
          maxlength="10"
          :rules="rules.numberValidationRule"
        ></v-text-field>

        <!-- Password -->

        <v-card-text class="sub-label mt-n4 ml-n2">Password</v-card-text>

        <v-text-field
          class="mx-2 mt-n2 custom-placeholder no-underline"
          density="compact"
          :rules="rules.password"
          placeholder="*******"
          v-model="password"
          :append-inner-icon="visible ? 'mdi-eye' : 'mdi-eye-off'"
          :type="visible ? 'text' : 'password'"
          @click:append-inner="toggleVisibility"
          solo
        ></v-text-field>
      </v-form>
    </v-card-text>

    <v-card-actions class="mt-0">
      <v-spacer />
      <v-btn block size="large" class="test-login-btn" @click="Register()"
        ><span class="login--text" v-if="!loginState">Register</span>
        <v-progress-circular
          v-if="loginState"
          indeterminate
          color="purple"
        ></v-progress-circular>
      </v-btn>
      <v-spacer />
    </v-card-actions>

    <v-card-actions class="mt-n1">
      <v-spacer />
      <v-btn class="forgot-text mt-2" text >
        Visit our page</v-btn
      >


      <v-spacer />
    </v-card-actions>

    <v-card-actions class="mt-4">
   
      <v-spacer />
      <v-btn
        class="forgot-text mt-n5"
        variant="outlined"
        
      >
        <v-icon color="blue" class="mr-3">mdi-facebook</v-icon> Facebook</v-btn
      >
      <v-spacer />
    </v-card-actions>
  </div>


  <!-- </v-container> -->
</template>

<script>
export default {
  name: "SignUp",

  data() {
    return {
      isValid: false,
      visible: false,
      loginState: false,
      screenSize: "",
      isValidPhoneNumber: true,
      formData: {
        username: "",
        phone: "",
        password: "",
      },
      showPassword: false,
      rules: {
        required: [(value) => !!value || "Required."],
        numberValidationRule: [
          (value) => !!value || "Required.",
          (value) => /^[0-9]*$/.test(value) || "Only numbers are allowed.",
          (value) => value.length === 10 || "Must be exactly 10 numbers.",
          (value) =>
            /^(01|07)/.test(value) ||
            "Phone number must start with '01' or '07'",
        ],
      },
    };
  },

  computed: {
    alertMessage() {
      return this.$store.getters["auth/authGetters"]("alert");
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
    this.alertMessage.status = "";
    this.alertMessage.message = "";
  },
  methods: {
    Register() {
      if (
        this.formData.password === "" ||
        this.formData.phone === "" ||
        this.formData.username === ""
      ) {
        this.alertMessage.status = "error";
        this.alertMessage.message = "all fields required";
      } else {
        this.formData.phone = String(this.formData.phone);
        this.loginState = true;
        this.$store.dispatch("auth/register", this.formData);
      }
    },

    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },

    validatePhoneNumber() {
      // Regular expression for a simple phone number validation
      const phoneRegex = /^(\+254|0)[1-9]\d{8}$/;

      // Test if the input matches the regular expression
      this.isValidPhoneNumber = phoneRegex.test(this.formData.phone);
    },
    dismissAlert() {
      // Clear the alert
      this.alertMessage.status = "";
      this.alertMessage.message = "";
    },
    
    toggleVisibility() {
      this.visible = !this.visible;
    },
  },

  watch: {
    alertMessage: {
      handler: function () {
        if (this.alertMessage.status !== 1000) {
          this.loginState = false;
        }
      },
    },
  },
};
</script>

<style>
@import url("../style.css");
</style>
