<template>
  <!-- <v-container fluid class="login-container justify-center align-center"> -->
  <div class="center-horizontally">
    <v-row class="mx-2 mt-6">
      <v-img :src="require('@/assets/sakata.png')" class="img" />
    </v-row>

    <v-row class="signInTitle mx-2 mt-6"> Sign In </v-row>

    <v-alert class="mt-4" dense v-if="alertMessage.status !== ''"
      :type="alertMessage.status === 'success' ? 'success' : 'error'" @click="dismissAlert">
      <span class="pass-text">{{ alertMessage.message }}</span>
    </v-alert>

    <!-- <v-card-text class="login-form mt-n3"> -->
    <v-form ref="loginForm" v-model="isValid">
      <!--User name-->
      <v-card-text class="sub-label mt-1 ml-n2">Phone Number</v-card-text>

      <v-text-field ref="phone" density="compact" :rules="rules.required" v-model="formData.phone"
        class="mx-2 mt-n2 custom-placeholder no-underline " placeholder="+254 | 7123456789" solo></v-text-field>

      <v-card-text class="sub-label mt-n2 ml-n2">Password</v-card-text>

      <v-text-field class="mx-2 mt-n2 custom-placeholder no-underline" density="compact" :rules="rules.password"
        placeholder="*******" v-model="formData.password" :append-inner-icon="visible ? 'mdi-eye' : 'mdi-eye-off'"
        :type="visible ? 'text' : 'password'" @click:append-inner="toggleVisibility" solo></v-text-field>
      <!-- Password -->
    </v-form>
    <!-- </v-card-text> -->
    <v-card-actions class="mt-n3">
      <v-btn :to="{ name: 'forgot' }" class="rem-text mt-n4 ml-n5" text>
        <v-checkbox-btn v-model="enabled" class="pe-2"></v-checkbox-btn>
        <p class="ml-n4">Remember me</p>
      </v-btn>
      <v-spacer />
      <v-btn :to="{ name: 'forgot' }" class="capitalize-first-letter forgot-text mt-n4 ml-6" variant="text">
        Forgot password?</v-btn>
    </v-card-actions>

    <v-card-actions class="mt-n5">
      <v-spacer />
      <v-btn block size="large" class="test-login-btn" @click="Login()"><span class="login--text" v-if="!loginState">Log
          in</span>
        <v-progress-circular v-if="loginState" indeterminate color="#3f2360"></v-progress-circular>
      </v-btn>
      <v-spacer />
    </v-card-actions>

    <v-card-actions class="mt-n1">
      <v-spacer />
      <v-btn class="forgot-text mt-n5" text :to="{ name: 'signUp' }">
        Visit our page</v-btn>
      <v-spacer />
    </v-card-actions>

    <v-card-actions class="mt-n2">
      <v-spacer />
      <v-btn class="forgot-text mt-n5" variant="outlined" :to="{ name: 'signUp' }">
        <v-icon color="blue" class="mr-3">mdi-facebook</v-icon> Facebook</v-btn>
      <v-spacer />
    </v-card-actions>
    <!-- </v-card> -->
  </div>
  <!-- </v-container> -->
</template>

<script>
// import { AuthService } from "@/modules/auth";
export default {
  name: "testPage",

  data() {
    return {
      isValid: false,
      loginState: false,
      screenSize: "",
      visible: false,
      formData: {
        phone: "",
        password: "",
      },
      showPassword: false,
      rules: {
        required: [
          (value) => !!value || "Required.",
          (value) => /^[0-9]*$/.test(value) || "Only numbers are allowed.",
        ],
        password: [(value) => !!value || "Required."],
      },
    };
  },

  computed: {
    alertMessage() {
      return this.$store.getters["auth/authGetters"]("alert");
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
    this.alertMessage.status = "";
    this.alertMessage.message = "";
  },
  methods: {
    Login() {
      if (this.formData.password === "" || this.formData.phone === "") {
        this.alertMessage.status = "error";
        this.alertMessage.message = "all fields required";
      } else {
        this.formData.phone = String(this.formData.phone);
        this.loginState = true;
        this.$store.dispatch("auth/login", this.formData);
      }
    },
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },

    dismissAlert() {
      // Clear the alert
      this.alertMessage.status = "";
      this.alertMessage.message = "";
    },

    toggleVisibility() {
      this.visible = !this.visible;
    },
  },

  watch: {
    alertMessage: {
      handler: function () {
        if (this.alertMessage.status !== 1000) {
          this.loginState = false;
        }
      },
    },
  },
};
</script>

<style>
@import url("../style.css");
</style>
