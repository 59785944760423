import { createRouter, createWebHistory } from "vue-router";
import AuthRoutes from "@/modules/auth/authRoutes";
import DashboardRoutes from "@/modules/dashboard/dashboardRoutes";
import graphRoutes from "@/modules/graph/graphRoutes";
import mainRoutes from "@/modules/Main/mainRoutes";
import nextFactory from "@/modules/auth/middleware/MiddlewareFactory";

const routes = [
  ...AuthRoutes,
  ...DashboardRoutes,
  ...graphRoutes,
  ...mainRoutes,
  // Other routes
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // Reset page to top
  window.scrollTo(0, 0);

  const token = !!localStorage.getItem("crash_token");

  if (to.meta.requiresAuth && !token) {
    // Redirect to sign-in page if route requires authentication and user is not authenticated
    return next({ name: "signIn" });
  }
  if (to.name === "Promotion") {
    return next();
  }

  if (token && to.name === "LandingPage") {
    return next();
  }

  // if (!to.meta.requiresAuth && token && to.name !== "LandingPage") {
  //   // Redirect to the LandingPage if user is authenticated and trying to access a public page
  //   return next({ name: "LandingPage" });
  // }

  
  // if (!to.meta.requiresAuth && token) {
  //   // Prevent infinite redirection loop
  //   if (to.name !== "LandingPage") {
  //     return next({ name: "LandingPage" });
  //   }
  // }

 

  // Middleware handling
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);
    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
