<template>
    <!-- <v-container fluid class="login-container justify-center align-center"> -->
    <div class="center-horizontally">
        <v-card-action><v-icon color="white" size="large" @click="redirect('profilePage')"
                class="mt-4">mdi-chevron-left</v-icon> </v-card-action>
        <!-- <v-card variant="flat" class="Login-Card"> -->

        <!-- <v-card-text class="signInTitle">Hello, Welcome to LimboBust</v-card-text> -->
        <v-card-text class="signInTitle mt-4">Manual Reconciliation</v-card-text>
        <!-- <v-card-text class="sub-signInTitle mt-n6">Play Now and Win Big! 18+ Only</v-card-text> -->

        <v-alert dense v-if="alertMessage.status !== ''" :type="alertMessage.status === 'success' ? 'success' : 'error'"
            @click="dismissAlert">
            <span class="pass-text">{{ alertMessage.message }}</span>
        </v-alert>

        <v-card-text class="login-form mt-n3">
            <v-form ref="loginForm" v-model="isValid">
                <!--User name-->
                <v-card-text class="sub-label mt-n4 ml-n2"><span class="asterisk">*</span>Deposit to</v-card-text>
                <input ref="phone" density="compact" :rules="rules.required" v-model="formData.phone"
                    placeholder="Enter phone" class="log-field" type="number" variant="outlined" required />

                <v-card-text class="sub-label mt-3 ml-n2"><span class="asterisk">*</span>Main Wallet</v-card-text>

                <!-- Password -->
                <input density="compact" ref="password" :rules="rules.password" v-model="formData.password"
                    placeholder="Enter Password" :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" type="number"
                    @click:append-inner="visible = !visible" variant="outlined" class="log-field" required />
            </v-form>


            <v-card-text class="sub-label mt-3">
                <div class="live-Sport ml-n1 ">Method</div>
                <div class="sub-label  ">

                    Please enter the Transation Code which M-pesa sent to your Mobile Phone via SMS,e.g ML32A2XXXX.
                    Enter M-pesa code or Paste the whole message
                </div>


            </v-card-text>
            <!-- Password -->
            <input density="compact" ref="password" :rules="rules.password" v-model="formData.password"
                placeholder="Enter Mpesa code or Paste the whole message" type="text"
                @click:append-inner="visible = !visible" variant="outlined" class="log-field" required />


            <v-card-text class="sub-label mt-3">

                <div class="sub-label  ">

                    Warm Notice:
                    Manual Reconciliation can be used in the situation where by you have received an SMS from M-Pesa
                    Paybill informing you that your transaction was successful but no money reflected in Sakata
                    account.
                </div>


            </v-card-text>


        </v-card-text>


        <v-card-actions class="mt-n6">
            <v-spacer />
            <v-btn size="large" class="test-login-btn"><span class="login--text" v-if="!loginState">Continue</span>
                <v-progress-circular v-if="loginState" indeterminate color="purple"></v-progress-circular>
            </v-btn>
            <v-spacer />
        </v-card-actions>


        <!-- </v-card> -->
    </div>
    <!-- </v-container> -->
</template>

<script>
// import { AuthService } from "@/modules/auth";
export default {
    name: "testPage",


    data() {
        return {
            isValid: false,
            loginState: false,
            screenSize: "",
            visible: false,
            formData: {
                phone: "",
                password: "",
            },
            showPassword: false,
            rules: {
                required: [
                    (value) => !!value || "Required.",
                    (value) => /^[0-9]*$/.test(value) || "Only numbers are allowed.",
                ],
                password: [(value) => !!value || "Required."],
            },
        };
    },

    computed: {
        alertMessage() {
            return this.$store.getters["auth/authGetters"]("alert");
        },
    },

    mounted() {
        this.checkScreenSize(); // Check screen size initially
        window.addEventListener("resize", this.checkScreenSize);
        this.alertMessage.status = "";
        this.alertMessage.message = "";
    },
    methods: {
        Login() {
            if (this.formData.password === "" || this.formData.phone === "") {
                this.alertMessage.status = "error";
                this.alertMessage.message = "all fields required";
            } else {
                this.formData.phone = String(this.formData.phone)
                this.loginState = true;
                this.$store.dispatch("auth/login", this.formData);
            }
        },
        checkScreenSize() {
            const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
            this.screenSize = isSmallScreen ? "Small" : "Large";
        },

        dismissAlert() {
            // Clear the alert
            this.alertMessage.status = "";
            this.alertMessage.message = "";
        },
        redirect(val) {
            this.$router.push({ name: val });
        },
    },

    watch: {
        alertMessage: {
            handler: function () {
                if (this.alertMessage.status !== 1000) {
                    this.loginState = false;
                }
            },
        },
    },
};
</script>

<style>
@import url("../style.css");
</style>