<template>
<div>

    <v-dialog v-model="dialog" max-width="500" v-if="screenSize === 'Large'">
        <!-- <v-card variant="flat" class="terms-card"> -->
        <v-card-action class="mt-4"><v-icon color="white" size="large" @click="redirect('profilePage')"
                class="mt-4">mdi-chevron-left</v-icon>
        </v-card-action>
        <v-card-text class="signInTitle mt-2">TERMS & CONDITIONS</v-card-text>

        <div class="sub-label terms-dialog">

            1.1. These T&C are an agreement between Reys and Meys Limited (the
            `Company`) and You (the `Player`).
            <br />
            1.2. These T&C apply to the use of the sakata.com (SAKATA,
            PakaKumi, Website) by You, the Player.
            <br />
            1.3. These T&C are applied when you click 'I Accept Terms And
            Conditions' and register. By registering, you acknowledge to
            sakata that you have read and accepted the T&C and that you are at
            least 18 years old. Using any part of the Website after logging in
            signifies that you agree to the T&C.
            <br />
            1.4. You must read these T&C before registering and clicking on
            the 'I Accept Terms And Conditions' checkbox. Not agreeing with
            these T&C at any time makes it prohibited to use Sakata.
            <br />
            1.5. You understand and agree that Sakata may modify and/or amend
            these T&C at any time.
            <br />
            <br />
            2. ELIGIBILITY TO USE SAKATA
            <br />
            2.1. You may use Sakata only if:
            <br />
            2.2. You are at least 18 years old and have reached the minimum
            legal age if required in the jurisdiction where You are residing;
            and
            <br />
            2.3. It is legal for You to use Sakata in accordance to the laws
            in Your jurisdiction of residence.
            <br />
            2.4. It is Your responsibility to make sure that You don't breach
            any laws by using Sakata.
            <br />
            2.5. Residents in the following markets are not allowed to use
            Sakata: Australia, USA, Aruba, Bonaire, Belize, Curaçao,
            Netherlands, France, Saba, St Martin, Statia
            <br />
            <br />
            3. REGISTRATION AND HANDLING OF YOUR SAKATA ACCOUNT
            <br />
            3.1.1. In order place any wagers on Sakata, you must register and
            open an account.
            <br />
            3.1.2. All required information must be entered truthfully during
            the registration, most importantly a valid and functioning
            email/phone where you can be reached; Entering an invalid
            phone/email address will make it impossible to reset your password
            or withdraw any funds. This also applies if you register through
            Google, Facebook or any such third party login/authentication
            service. It is Your responsibility to make sure that the
            information used at the time of registration is true, correct and
            complete.
            <br />
            3.1.3. During registration, You will choose Your phone/email and
            password. It is Your responsibility to keep your login details
            secret. Sakata is not to be held responsible for any misuse of
            Your Account by anyone if you have disclosed Your account
            information, whether accidental or intentional, whether passive or
            active, to a third party.
            <br />
            3.1.4. Sakata may refuse to let you register a new User Account or
            may close Your Account. In such a case, all virtual funds (minus
            the transaction fee) in Your Account will be sent back to You.
            <br />
            <br />
            3.2. CLOSING OF ACCOUNT
            <br />
            3.2.1. You can close Your Account by emailing support@sakata.com.
            Sakata will return any account balance minus withdrawal fees,
            using a method of Sakata's choice.
            <br />
            3.2.2. Sakata may choose to close Your Account and refund the
            account balance, minus withdrawal fees, at any time and without
            providing a reason.
            <br />
            <br />
            3.3. DEPOSITS INTO YOUR ACCOUNT
            <br />
            3.3.1. Sakata may credit a player's account with a 'bonus'. Bonus
            conditions are set out on the bonus page from time to time.
            <br />
            3.3.2. Deposits into your Account are made by transferring money
            from the mobile number associated with your account (and ONLY from
            that number) to Sakata Paybill Number.
            <br />
            3.3.3. Deposited amounts are made available on Your Account as
            soon as the payment is confirmed by MPESA.
            <br />
            3.3.4. We may use implement procedures to confirm your identity
            when before deposits are credited into your Account.
            <br />
            3.3.5. If requested to send us documents, it's Your responsibility
            to send us documents that are genuine. Any faked or misleading
            documents may result in closure of Your Account and return of
            unsused deposited funds.
            <br />
            <br />
            3.4. WITHDRAWALS FROM YOUR ACCOUNT
            <br />
            3.4.1. If Your Account is erroneously credited with winnings, the
            amount will be subtracted from your Account. If the balance was
            already withdrawn prior to us noticing the error, you owe the
            amount to us. If Your Account is erroneously credited, you have to
            let us know immediately by sending an email to support@sakata.com.
            <br />
            3.4.2. Sakata may carry out arbitrary verification procedures
            before sending out pay-outs. This verification may include asking
            for photo copies of a player's ID, passport, and requiring the
            customer to sign a message for an address that an early deposit
            was made from.
            <br />
            3.4.3. Withdrawals are made by directly transferring money via
            MPESA to the mobile number associated with your account (and ONLY
            from that number).
            <br />
            <br />
            4. YOUR OBLIGATIONS
            <br />
            4.1. By using Sakata, You warrant that:
            <br />
            4.1.1. You use Sakata on your own behalf and using Your one and
            only Sakata account;
            <br />
            4.1.2. You are at least 18 years of age or have reached any higher
            minimum age if required in the jurisdiction where You are
            residing; and
            <br />
            4.1.3. You use Sakata non-professionally for recreational reasons
            only;
            <br />
            4.1.4. You understand that if you are using Sakata, You risk
            losing any money deposited into Your Account;
            <br />
            4.1.5. You understand that currency exchange rate can vary
            dramatically over time depending on current market value. If the
            exchange rate to EUR changes, Your Account's EUR balance will also
            change.
            <br />
            4.1.6. You are responsible for declaring any taxes that apply to
            You for any winnings from Sakata;
            <br />
            4.2. Rude and/or obscene chat messages in Sakata's chat system or
            in Your Account name are not allowed.
            <br />
            4.3. If a bet is completed or a payment is made by Sakata in
            error, Sakata reserves the right to retroactively cancel or
            correct bets containing the error by re-settling all the bets at
            the correct spreads/terms/prices time of the bet.
            <br />
            4.4. If You become aware of errors in Sakata, You agree to not
            take advantage of them. You also agree to report any errors
            immediately to the Sakata support. We usually reward such reports
            with a bounty. If You fail to report such errors, Sakata has the
            right to compensation of any costs related to any error(s).
            <br />
            4.5. If a game round is started but is later miscarried, Sakata
            will refund the bet amount to the User Account.
            <br />
            4.6. sakata.com may limit or reject wagers. You are not allowed to
            bet an amount greater than Your Account balance. Wins are credited
            to the user's personal Account.
            <br />
            4.7. Sakata reserves the right to retain deposits if there is
            reasonable suspicion of manipulation of the system.
            <br />
            4.8. We may require additional verification if we are suspecting
            that transactions are in any way fraudulent.
            <br />
            4.9. sakata.com reserves the right to void in full or partially
            any wager, would we deem that any of the conditions stipulated in
            these T&C are not met.
            <br />
            4.10. sakata.com reserves the right to limit or decline any wager.
            <br />
            4.11. Sakata reserves the right to close Your Account and return
            the balance (minus any transaction fees) at any time and without
            any reason.
            <br />
            <br />
            5. PRIVACY POLICY
            <br />
            5.1. When You open a Sakata account, the information we collect
            may be shared with third parties to help verify Your identity and
            process transactions.
            <br />
            5.2. Sakata will take necessary steps to make sure that Your data
            is handled securely and according to industry standards.
            <br />
            5.3. Sakata may use Your data for marketing purposes. If You don't
            want Sakata to use Your data for marketing purposes, you may opt
            out by sending an email to support@sakata.com.
            <br />
            5.4. Sakata uses cookies. By using the Website, you consent to our
            use of cookies.
            <br />
            5.5. For more detailed information about our privacy practices,
            please refer to our Privacy Policy available on sakata.com.
            <br />
            <br />
            6. THIRD PARTY LINKS
            <br />
            6.1. The Website may contain links to other websites operated by
            third parties. Sakata does not control those websites and is not
            responsible for their content or privacy practices.
            <br />
            <br />
            7. DISCLAIMER AND LIMITATION OF LIABILITY
            <br />
            7.1. Sakata is provided "as is" and "as available" without any
            warranties of any kind, whether express or implied.
            <br />
            7.2. Sakata does not guarantee that the Website will be error-free
            or uninterrupted.
            <br />
            7.3. In no event shall Sakata be liable for any indirect,
            incidental, consequential, or punitive damages arising out of or
            in connection with the use of the Website.
            <br />
            <br />
            8. MISCELLANEOUS
            <br />
            8.1. These T&C constitute the entire agreement between You and
            Sakata regarding the use of the Website.
            <br />
            8.2. If any provision of these T&C is found to be invalid or
            unenforceable, the remaining provisions shall remain in full force
            and effect.
            <br />
            8.3. These T&C shall be governed by and construed in accordance
            with the laws of the jurisdiction in which the Company is located.
            <br />
            <br />
            9. CONTACT US
            <br />
            9.1. If You have any questions or concerns about these T&C, please
            contact us at support@sakata.com.

        </div>
        <!-- </v-card> -->
    </v-dialog>
    <div v-if="screenSize === 'Small'"> 
        <v-card-action class="mt-4"><v-icon color="white" size="large"
                @click="redirect('profilePage')" class="mt-4">mdi-chevron-left</v-icon>
        </v-card-action>
        <v-card-text class="signInTitle mt-n6">TERMS & CONDITIONS</v-card-text>

        <div class="sub-label terms-dialog">

            1.1. These T&C are an agreement between Reys and Meys Limited (the
            `Company`) and You (the `Player`).
            <br />
            1.2. These T&C apply to the use of the sakata.com (SAKATA,
            PakaKumi, Website) by You, the Player.
            <br />
            1.3. These T&C are applied when you click 'I Accept Terms And
            Conditions' and register. By registering, you acknowledge to
            sakata that you have read and accepted the T&C and that you are at
            least 18 years old. Using any part of the Website after logging in
            signifies that you agree to the T&C.
            <br />
            1.4. You must read these T&C before registering and clicking on
            the 'I Accept Terms And Conditions' checkbox. Not agreeing with
            these T&C at any time makes it prohibited to use Sakata.
            <br />
            1.5. You understand and agree that Sakata may modify and/or amend
            these T&C at any time.
            <br />
            <br />
            2. ELIGIBILITY TO USE SAKATA
            <br />
            2.1. You may use Sakata only if:
            <br />
            2.2. You are at least 18 years old and have reached the minimum
            legal age if required in the jurisdiction where You are residing;
            and
            <br />
            2.3. It is legal for You to use Sakata in accordance to the laws
            in Your jurisdiction of residence.
            <br />
            2.4. It is Your responsibility to make sure that You don't breach
            any laws by using Sakata.
            <br />
            2.5. Residents in the following markets are not allowed to use
            Sakata: Australia, USA, Aruba, Bonaire, Belize, Curaçao,
            Netherlands, France, Saba, St Martin, Statia
            <br />
            <br />
            3. REGISTRATION AND HANDLING OF YOUR SAKATA ACCOUNT
            <br />
            3.1.1. In order place any wagers on Sakata, you must register and
            open an account.
            <br />
            3.1.2. All required information must be entered truthfully during
            the registration, most importantly a valid and functioning
            email/phone where you can be reached; Entering an invalid
            phone/email address will make it impossible to reset your password
            or withdraw any funds. This also applies if you register through
            Google, Facebook or any such third party login/authentication
            service. It is Your responsibility to make sure that the
            information used at the time of registration is true, correct and
            complete.
            <br />
            3.1.3. During registration, You will choose Your phone/email and
            password. It is Your responsibility to keep your login details
            secret. Sakata is not to be held responsible for any misuse of
            Your Account by anyone if you have disclosed Your account
            information, whether accidental or intentional, whether passive or
            active, to a third party.
            <br />
            3.1.4. Sakata may refuse to let you register a new User Account or
            may close Your Account. In such a case, all virtual funds (minus
            the transaction fee) in Your Account will be sent back to You.
            <br />
            <br />
            3.2. CLOSING OF ACCOUNT
            <br />
            3.2.1. You can close Your Account by emailing support@sakata.com.
            Sakata will return any account balance minus withdrawal fees,
            using a method of Sakata's choice.
            <br />
            3.2.2. Sakata may choose to close Your Account and refund the
            account balance, minus withdrawal fees, at any time and without
            providing a reason.
            <br />
            <br />
            3.3. DEPOSITS INTO YOUR ACCOUNT
            <br />
            3.3.1. Sakata may credit a player's account with a 'bonus'. Bonus
            conditions are set out on the bonus page from time to time.
            <br />
            3.3.2. Deposits into your Account are made by transferring money
            from the mobile number associated with your account (and ONLY from
            that number) to Sakata Paybill Number.
            <br />
            3.3.3. Deposited amounts are made available on Your Account as
            soon as the payment is confirmed by MPESA.
            <br />
            3.3.4. We may use implement procedures to confirm your identity
            when before deposits are credited into your Account.
            <br />
            3.3.5. If requested to send us documents, it's Your responsibility
            to send us documents that are genuine. Any faked or misleading
            documents may result in closure of Your Account and return of
            unsused deposited funds.
            <br />
            <br />
            3.4. WITHDRAWALS FROM YOUR ACCOUNT
            <br />
            3.4.1. If Your Account is erroneously credited with winnings, the
            amount will be subtracted from your Account. If the balance was
            already withdrawn prior to us noticing the error, you owe the
            amount to us. If Your Account is erroneously credited, you have to
            let us know immediately by sending an email to support@sakata.com.
            <br />
            3.4.2. Sakata may carry out arbitrary verification procedures
            before sending out pay-outs. This verification may include asking
            for photo copies of a player's ID, passport, and requiring the
            customer to sign a message for an address that an early deposit
            was made from.
            <br />
            3.4.3. Withdrawals are made by directly transferring money via
            MPESA to the mobile number associated with your account (and ONLY
            from that number).
            <br />
            <br />
            4. YOUR OBLIGATIONS
            <br />
            4.1. By using Sakata, You warrant that:
            <br />
            4.1.1. You use Sakata on your own behalf and using Your one and
            only Sakata account;
            <br />
            4.1.2. You are at least 18 years of age or have reached any higher
            minimum age if required in the jurisdiction where You are
            residing; and
            <br />
            4.1.3. You use Sakata non-professionally for recreational reasons
            only;
            <br />
            4.1.4. You understand that if you are using Sakata, You risk
            losing any money deposited into Your Account;
            <br />
            4.1.5. You understand that currency exchange rate can vary
            dramatically over time depending on current market value. If the
            exchange rate to EUR changes, Your Account's EUR balance will also
            change.
            <br />
            4.1.6. You are responsible for declaring any taxes that apply to
            You for any winnings from Sakata;
            <br />
            4.2. Rude and/or obscene chat messages in Sakata's chat system or
            in Your Account name are not allowed.
            <br />
            4.3. If a bet is completed or a payment is made by Sakata in
            error, Sakata reserves the right to retroactively cancel or
            correct bets containing the error by re-settling all the bets at
            the correct spreads/terms/prices time of the bet.
            <br />
            4.4. If You become aware of errors in Sakata, You agree to not
            take advantage of them. You also agree to report any errors
            immediately to the Sakata support. We usually reward such reports
            with a bounty. If You fail to report such errors, Sakata has the
            right to compensation of any costs related to any error(s).
            <br />
            4.5. If a game round is started but is later miscarried, Sakata
            will refund the bet amount to the User Account.
            <br />
            4.6. sakata.com may limit or reject wagers. You are not allowed to
            bet an amount greater than Your Account balance. Wins are credited
            to the user's personal Account.
            <br />
            4.7. Sakata reserves the right to retain deposits if there is
            reasonable suspicion of manipulation of the system.
            <br />
            4.8. We may require additional verification if we are suspecting
            that transactions are in any way fraudulent.
            <br />
            4.9. sakata.com reserves the right to void in full or partially
            any wager, would we deem that any of the conditions stipulated in
            these T&C are not met.
            <br />
            4.10. sakata.com reserves the right to limit or decline any wager.
            <br />
            4.11. Sakata reserves the right to close Your Account and return
            the balance (minus any transaction fees) at any time and without
            any reason.
            <br />
            <br />
            5. PRIVACY POLICY
            <br />
            5.1. When You open a Sakata account, the information we collect
            may be shared with third parties to help verify Your identity and
            process transactions.
            <br />
            5.2. Sakata will take necessary steps to make sure that Your data
            is handled securely and according to industry standards.
            <br />
            5.3. Sakata may use Your data for marketing purposes. If You don't
            want Sakata to use Your data for marketing purposes, you may opt
            out by sending an email to support@sakata.com.
            <br />
            5.4. Sakata uses cookies. By using the Website, you consent to our
            use of cookies.
            <br />
            5.5. For more detailed information about our privacy practices,
            please refer to our Privacy Policy available on sakata.com.
            <br />
            <br />
            6. THIRD PARTY LINKS
            <br />
            6.1. The Website may contain links to other websites operated by
            third parties. Sakata does not control those websites and is not
            responsible for their content or privacy practices.
            <br />
            <br />
            7. DISCLAIMER AND LIMITATION OF LIABILITY
            <br />
            7.1. Sakata is provided "as is" and "as available" without any
            warranties of any kind, whether express or implied.
            <br />
            7.2. Sakata does not guarantee that the Website will be error-free
            or uninterrupted.
            <br />
            7.3. In no event shall Sakata be liable for any indirect,
            incidental, consequential, or punitive damages arising out of or
            in connection with the use of the Website.
            <br />
            <br />
            8. MISCELLANEOUS
            <br />
            8.1. These T&C constitute the entire agreement between You and
            Sakata regarding the use of the Website.
            <br />
            8.2. If any provision of these T&C is found to be invalid or
            unenforceable, the remaining provisions shall remain in full force
            and effect.
            <br />
            8.3. These T&C shall be governed by and construed in accordance
            with the laws of the jurisdiction in which the Company is located.
            <br />
            <br />
            9. CONTACT US
            <br />
            9.1. If You have any questions or concerns about these T&C, please
            contact us at support@sakata.com.

        </div>
    </div>
</div>


</template>

<script>
export default {
    name: "testPage",

    data() {
        return {
            dialog: true,
            isValid: false,
            screenSize: "",
        };
    },

    computed: {},

    mounted() {
        this.checkScreenSize(); // Check screen size initially
        window.addEventListener("resize", this.checkScreenSize);
    },
    methods: {
        checkScreenSize() {
            const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
            this.screenSize = isSmallScreen ? "Small" : "Large";
        },

        redirect(val) {
            this.$router.push({ name: val });
        },
    },

    watch: {},
};
</script>

<style>
@import url("../style.css");
</style>
