


const authRoutes = [
  {

  }
];

export default authRoutes;
