<template>
  <div class="center-horizontally">
    <v-card-text class="signInX"> </v-card-text>

    <!-- <v-card-text class="signInTitle">Hello, Welcome to LimboBust</v-card-text> -->
    <v-card-text class="signInTitle mt-n4">Reset Password Below</v-card-text>

    <v-alert dense v-if="alertMessage.status !== ''" :type="alertMessage.status === 'success' ? 'success' : 'error'"
      @click="dismissAlert">
      <span class="pass-text">{{ alertMessage.message }}</span>
    </v-alert>
    <v-card-text class="login-form">
      <v-form ref="loginForm" v-model="isValid">
        <!-- Password -->
        <v-card-text class="sub-label mt-n6"><span class="asterisk">*</span>New Password</v-card-text>
        <input ref="password" :rules="rules.required" v-model="formData.password"
          placeholder="Enter New Password" :type="visible ? 'text' : 'password'"
          @click:append-inner="visible = !visible" density="compact" variant="outlined"
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" class="log-field" />
      </v-form>
    </v-card-text>

    <v-card-actions class="mt-0">
      <v-spacer />
      <v-btn  size="large" class="test-login-btn" @click="Login()"><span class="login--text"
          v-if="!loginState">Submit</span>
        <v-progress-circular v-if="loginState" indeterminate color="purple"></v-progress-circular>
      </v-btn>
      <v-spacer />
    </v-card-actions>
  </div>
</template>

<script>
export default {
  name: "testPage",

  data() {
    return {
      isValid: false,
      visible: false,
      loginState: false,
      screenSize: "",
      formData: {
        phone: "",
        password: "",
      },
      showPassword: false,
      rules: {
        required: [
          (value) => !!value || "Required.",
          (value) => /^[0-9]*$/.test(value) || "Only numbers are allowed.",
        ],
      },
    };
  },

  computed: {
    alertMessage() {
      return this.$store.getters["auth/authGetters"]("resetAlert");
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    Login() {
      if (this.formData.password === "") {
        this.alertMessage.status = "error";
        this.alertMessage.message = "all fields required";
      } else {
        const Phone = atob(this.$route.params.code)
        this.formData.phone = String(Phone)
        this.loginState = true;
        this.$store.dispatch("auth/resetPassword", this.formData);
      }
    },
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
  
    dismissAlert() {
      // Clear the alert
      this.alertMessage.status = "";
      this.alertMessage.message = "";
    },
  },

  watch: {
    alertMessage: {
      handler: function () {
        if (this.alertMessage.code !== 1000) {
          this.loginState = false;
        } else {
          this.loginState = false;

          this.$router.push({
            name: "signIn",
          });
        }
      },
    },
  },
};
</script>

<style>
@import url("../style.css");
</style>
