import call from "@/service/http";
import constants from "./graphConstants";
import eventBus from "@/utils/eventBus";

const graphModule = {
  namespaced: true,
  state: {
    bets: [],
    allChats: [],
    topBets: [],
    myBets: [],
    betHistory: [],
    transHistory: [],
    betText: "",
    spinner: false,
    referralLink:"",

    alert: {
      status: "",
      message: "",
    },
  },
  mutations: {
    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
      };
    },

    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    graphGetters: (state) => (val) => state[val],
  },
  actions: {
    sendBet: ({ commit }, data) => {
      call("post", constants.SEND_BET, data)
        .then((res) => {
          if (res.data.status_code === 1000) {
            // EventBus.$emit("bet-sent-successfully");
            commit("SET_ALERT", {
              status: "success",
              message: res.data.status_desc,
            });
          } else {
            commit("SET_ALERT", {
              status: "error",
              message: res.data.status_desc,
            });
          }
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
        });
    },

    sendMessage: ({ commit }, data) => {
      call("post", constants.SEND_MESSAGE, data)
        .then((res) => {
          if (res.data.status_code === 1000) {
            // EventBus.$emit("bet-sent-successfully");
          }
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
        });
    },
    withdraw: ({ commit }, data) => {
      commit("MUTATE", { state: "spinner", value: true });
      call("post", constants.WITHDRAW, data)
        .then((res) => {
          if (res.data.status_code === 1000) {
            eventBus.emit("showToast", {
              code: res.data.status_code,
              message: res.data.status_desc,
            });
            commit("MUTATE", { state: "spinner", value: false });
          } else {
            eventBus.emit("showToast", {
              code: res.data.status_code,
              message: res.data.status_desc,
            });
            commit("MUTATE", { state: "spinner", value: false });
          }
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "spinner", value: false });
        });
    },
    deposit: ({ commit }, data) => {
      commit("MUTATE", { state: "spinner", value: true });
      call("post", constants.DEPOSIT, data)
        .then((res) => {
          console.log(res.data.status_desc);
          if (res.data.status_code === 1000) {
            eventBus.emit("showToast", {
              code: res.data.status_code,
              message: res.data.status_desc,
            });
            commit("MUTATE", { state: "spinner", value: false });
          } else {
            eventBus.emit("showToast", {
              code: res.data.status_code,
              message: res.data.status_desc,
            });
            commit("MUTATE", { state: "spinner", value: false });
          }
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "spinner", value: false });
        });
    },
    getAllChats: ({ commit }) => {
      call("get", constants.GET_CHATS)
        .then((res) => {
          if (res.data.status_code === 1000) {
            commit("MUTATE", { state: "allChats", value: res.data.records });
          }
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
        });
    },
    getAllBets: ({ commit }) => {
      call("get", constants.GET_ALL_BETS)
        .then((res) => {
          if (res.data.status_code === 1000) {
            commit("MUTATE", { state: "bets", value: res.data.data });
          }
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
        });
    },
    getTopBets: ({ commit }) => {
      call("get", constants.TOP_BETS)
        .then((res) => {
          if (res.data.status_code === 1000) {
            commit("MUTATE", { state: "topBets", value: res.data.data });
          }
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
        });
    },
    getMyBets: ({ commit }, data) => {
      call("post", constants.MY_BETS, data)
        .then((res) => {
          if (res.data.status_code === 1000) {
            commit("MUTATE", { state: "myBets", value: res.data.data });
          }
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
        });
    },
    getBetHistory: ({ commit }, data) => {
      call("post", constants.GET_BETHISTORY, data)
        .then((res) => {
          if (res.data.status_code === 1000) {
            commit("MUTATE", {
              state: "betHistory",
              value: res.data.status_desc,
            });
          }
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
        });
    },
    getTransHistory: ({ commit }, data) => {
      call("post", constants.TRANSHISTORY, data)
        .then((res) => {
          if (res.data.status_code === 1000) {
            commit("MUTATE", {
              state: "transHistory",
              value: res.data.status_desc,
            });
          }
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
        });
    },
    getReferralLink: ({ commit }, data) => {
      call("post", constants.Referral, data)
        .then((res) => {
          if (res.data.status_code === 1000) {
            commit("MUTATE", {
              state: "referralLink",
              value: res.data.referral_link,
            });
          }
        })
        .catch(() => {
          commit("SET_LOADING", false, { root: true });
        });
    },
  },
};

export default graphModule;
