// import forgot from "../Main/components/forgotPassword.vue";

import Layout from "../../App.vue";

const mainRoutes = [
  {
    path: "/",
    name: "Layout",
    component: Layout,
    children: [
      // {
      //   path: "/forgot-password",
      //   name: "forgot",
      //   component: forgot,
      // },
    ],
  },
];

export default mainRoutes;
