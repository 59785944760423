// import GraphsPage from "../dashboard/components/graphs";
import dashboard from "../dashboard/views/dashboard";
// import RegistrationPage from '@/views/RegistrationPage.vue';

const dashboardRoutes = [
  {
    path: "/dashboard",
    component: dashboard,
    children: [
   
      //   path: "",
      //   name: "graphsPage",
      //   component: GraphsPage,
      // },
    ],
  },
];

export default dashboardRoutes;
