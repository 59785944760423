<template>
  <v-app>
    <router-view />
    <v-snackbar v-model="playingsnackbar" class="mt-n12">
      {{ playingText }}

      <template v-slot:actions>
        <v-btn color="pink" variant="text" @click="playingsnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import AuthService from "./modules/auth/views/authService";



export default {
  name: "MainPage",

  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (AuthService.check()) {
        v.$router.replace({
          name: "LandingPage",
        });
      }
    });
  },

  data() {
    return {
      screenSize: "",
      playingsnackbar: false,
      playingText: ''
    };
  },

  computed: {},

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);

  
  },
  methods: {
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },
  },




};
</script>

<style>
@import url("./style.css");
</style>
